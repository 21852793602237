/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import useAuth from '../util/hooks/useAuth';
import SEO from '../components/SEO';
import Link from '../components/global/Link';

const SubSuccessfulPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');
  useAuth();

  const subMenu = { title: 'about', extra: true };

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      subMenu={subMenu}
      locations={data.locations.edges}
    >
      <SEO title="Subscription Successful" />
      <main className="page-subscribe-successful">
        <section className="grid-section grid-content-container">
          <div className="t-heading-container">
            <h1 className="t-uppercase">Subscription successful</h1>
            <p>You have been successfuly subscribed to our mailing list.</p>
            <Link
              button
              className={`btn-medium btn-center btn-space-large`}
              path={'/'}
              device={device}
            >
              back to the homepage
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default SubSuccessfulPage;

export const SubSuccessfulPageQuery = graphql`
  query SubSuccessfulPageQuery {
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
