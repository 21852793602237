import * as React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ description, title, image, noindex = false }) {
  const metaDescription = description ? description : 'this is the description';
  const companyName = 'Loch Gallery';
  const metaTitle = title ? title : companyName;
  const metaImage = `https://cms.lochgallery.com/${image}` || 'https://cms.lochgallery.com/sites/default/files/2021-05/Winnipeg.jpeg';

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={metaTitle ? `%s | ${companyName}` : null}
    >
      {/* <meta name="robots" content="noindex" /> for development only */}
      {noindex ? (<meta name="robots" content="noindex" />) : null}
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content={`website`} />
      <meta nmae="og:image" content={metaImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={`Loch Gallery`} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={`metaDescription`} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  );
}

export default SEO;
